// import AWS from "aws-sdk";
// import Jimp from "jimp";
// import orderBy from "lodash/orderBy";
import camelCase from "lodash/camelCase";
import JSZip from "jszip";
// import format from "date-fns/format";
import {
  tmpSemanticTemplates,
  tmpSemanticPreviewTemplates,
} from "./tmpData/tmpTemplates";
import { tmpTemplateFamilies } from "./tmpData/tmpFamilies";
import { teamsTemplateFamilies } from "./tmpData/teamsTemplateFamilies";
import { teamsSemanticTemplates } from "./tmpData/teamsSemanticTemplates";

const loggerWrapper =
  (func, name) =>
  async (...args) => {
    process.env.DASHBOARD_STAGE !== "prod" && console.log("API call", name);

    const result = await func(...args);

    process.env.DASHBOARD_STAGE !== "prod" &&
      console.log("API call result", name, result);

    // console.log is executed with delay, data can be mutated in other place before console.log print it.
    // Create copy to avoid printing mutated data.
    if (Array.isArray(result)) {
      return [...result];
    }

    if (typeof result === "object" && result !== null) {
      return {
        ...result,
      };
    }

    return result;
  };

class ApiError extends Error {
  name = "ApiError";
}

const handleResponseWrapper =
  (func) =>
  async (...args) => {
    const response = await func(...args);

    if (response.ok) {
      return response.json();
    }
    const data = await response.text();

    let parsed;
    try {
      parsed = JSON.parse(data);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    throw new ApiError(
      parsed?.message ||
        (parsed?.[0].message &&
          parsed
            ?.map(({ instancePath, message }) => `${instancePath} ${message}`)
            .join("\n")) ||
        parsed ||
        data
    );
  };

export const listGeneralJourneys = loggerWrapper(async () => {
  //

  try {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_journeys`
      ],
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response.json();
  } catch (_) {
    return [];
  }
}, "getGeneralJourneys");

export const listJourneySemantics = loggerWrapper(
  async ({ journeyId, locale }) => {
    try {
      const response = await fetch(
        process.env[
          `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_journey_semantics`
        ]
          .replace("{id}", journeyId)
          .replace("{locale}", locale),
        {
          credentials: "include",
          method: "GET",
        }
      );

      return response.json();
    } catch (_) {
      return [];
    }
  },
  "listJourneySemantics"
);

export const listAllSemanticsForUser = loggerWrapper(
  async ({ journeyId, locale, surveyId }) => {
    try {
      const url = process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_journey_semantics`
      ]
        .replace("{id}", journeyId)
        .replace("{locale}", locale);
      const response = await fetch(`${url}?surveyId=${surveyId}`, {
        credentials: "include",
        method: "GET",
      });

      return response.json();
    } catch (_) {
      return [];
    }
  },
  "listAllSemanticsForUser"
);

export const listTemplateFamilies = loggerWrapper(async () => {
  //

  try {
    // const response = await fetch(
    //   process.env[
    //     `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_journey_semantics`
    //   ]
    //     .replace("{id}", journeyId)
    //     .replace("{locale}", locale),
    //   {
    //     headers: {
    //       Authorization: `Bearer ${idToken.jwtToken}`,
    //     },
    //     method: "GET",
    //   }
    // );

    // return response.json();
    // console.log("Temporarily using hard-coded data for templateFamilies");

    return tmpTemplateFamilies;
  } catch (_) {
    return [];
  }
}, "listTemplateFamilies");

export const listTeamsTemplateFamilies = loggerWrapper(async () => {
  try {
    return teamsTemplateFamilies;
  } catch (_) {
    return [];
  }
}, "listTeamsTemplateFamilies");

export const listSemanticTemplates = loggerWrapper(
  async ({ semanticTemplateId }) => {
    //

    try {
      // const response = await fetch(
      //   process.env[
      //     `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_journey_semantics`
      //   ]
      //     .replace("{id}", journeyId)
      //     .replace("{locale}", locale),
      //   {
      //     headers: {
      //       Authorization: `Bearer ${idToken.jwtToken}`,
      //     },
      //     method: "GET",
      //   }
      // );

      // return response.json();
      // console.log(
      //   "Temporarily using hard-coded data for semanticTemplates " +
      //     semanticTemplateId
      // );
      return tmpSemanticTemplates?.[semanticTemplateId]
        ? [tmpSemanticTemplates[semanticTemplateId]]
        : [];
    } catch (_) {
      return [];
    }
  },
  "listSemanticTemplates"
);

// Has issues
export const listTeamsSemanticTemplates = loggerWrapper(
  async ({ teamsSemanticTemplateId }) => {
    try {
      return teamsSemanticTemplates?.[teamsSemanticTemplateId]
        ? [teamsSemanticTemplates[teamsSemanticTemplateId]]
        : [];
    } catch (_) {
      return [];
    }
  },
  "listTeamsSemanticTemplates"
);

export const listSemanticPreviewTemplates = loggerWrapper(
  async ({ semanticPreviewTemplateId }) => {
    //

    try {
      // const response = await fetch(
      //   process.env[
      //     `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_journey_semantics`
      //   ]
      //     .replace("{id}", journeyId)
      //     .replace("{locale}", locale),
      //   {
      //     headers: {
      //       Authorization: `Bearer ${idToken.jwtToken}`,
      //     },
      //     method: "GET",
      //   }
      // );

      // return response.json();
      // console.log(
      //   "Temporarily using hard-coded data for semanticPreviewTemplates " +
      //     semanticPreviewTemplateId
      // );
      return tmpSemanticPreviewTemplates?.[semanticPreviewTemplateId]
        ? [tmpSemanticPreviewTemplates[semanticPreviewTemplateId]]
        : [];
    } catch (_) {
      return [];
    }
  },
  "listSemanticPreviewTemplates"
);

export const listJourneyMaterials = loggerWrapper(
  async ({ journeyId, locale }) => {
    try {
      const response = await fetch(
        process.env[
          `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_journey_materials`
        ]
          .replace("{id}", journeyId)
          .replace("{locale}", locale),
        {
          credentials: "include",
          method: "GET",
        }
      );

      return response.json();
    } catch (_) {
      return [];
    }
  },
  "listJourneyMaterials"
);

export const createJourneyMaterial = loggerWrapper(
  async ({ id, journeyId, ...material }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_create_journey_material`
      ]
        .replace("{id}", id)
        .replace("{journeyId}", journeyId),
      {
        body: JSON.stringify(material),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    return response.json();
  },
  "createJourneyMaterial"
);

export const updateJourneyMaterial = loggerWrapper(
  async ({ id, journeyId, ...material }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_update_journey_material`
      ]
        .replace("{id}", id)
        .replace("{journeyId}", journeyId),
      {
        body: JSON.stringify(material),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PUT",
      }
    );

    return response.json();
  },
  "updateJourneyMaterial"
);

export const deleteJourneyMaterial = loggerWrapper(
  async ({ id, journeyId }) => {
    await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_journey_material`
      ]
        .replace("{id}", id)
        .replace("{journeyId}", journeyId),
      {
        credentials: "include",
        method: "DELETE",
      }
    );
  },
  "deleteJourneyMaterial"
);

export const createJourneySemantic = loggerWrapper(async (semantic) => {
  const response = await fetch(
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_create_journey_semantic`
    ]
      .replace("{id}", semantic.id)
      .replace("{journeyId}", semantic.journeyId),
    {
      body: JSON.stringify(semantic),
      credentials: "include",
      method: "POST",
    }
  );

  if (!response.ok) {
    const data = await response.text();
    throw new Error(data);
  }

  return response.json();
}, "createJourneySemantic");

export const updateJourneySemantic = loggerWrapper(async (semantic) => {
  const response = await fetch(
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_update_journey_semantic`
    ]
      .replace("{id}", semantic.id)
      .replace("{journeyId}", semantic.journeyId),
    {
      body: JSON.stringify(semantic),
      credentials: "include",
      method: "PUT",
    }
  );

  if (!response.ok) {
    const data = await response.text();
    throw new Error(data);
  }

  return response.json();
}, "updateJourneySemantic");

export const callAiService = loggerWrapper(async (input) => {
  const response = await fetch(
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_ai_lambda2`
    ],
    {
      body: JSON.stringify(input),
      credentials: "include",
      method: "POST",
    }
  );

  if (!response.ok) {
    const data = await response.text();
    throw new Error(data);
  }

  return response.json();
}, "callAiService");

export const deleteJourneySemanticsByDay = loggerWrapper(
  async ({ journeyId, day }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_journey_semantics_by_day`
      ]
        .replace("{day}", day)
        .replace("{journeyId}", journeyId),
      {
        credentials: "include",
        method: "DELETE",
      }
    );

    if (!response.ok) {
      const data = await response.text();
      throw new Error(data);
    }

    return response.json();
  },
  "deleteJourneySemanticsByDay"
);

export const deleteJourneySemanticsByFlow = loggerWrapper(
  async ({ journeyId, flowId }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_journey_semantics_by_flow`
      ]
        .replace("{flowId}", flowId)
        .replace("{journeyId}", journeyId),
      {
        credentials: "include",
        method: "DELETE",
      }
    );

    if (!response.ok) {
      const data = await response.text();
      throw new Error(data);
    }

    return response.json();
  },
  "deleteJourneySemanticsByFlow"
);

export const deleteJourneySemantic = loggerWrapper(
  async ({ journeyId, id }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_journey_semantic`
      ]
        .replace("{id}", id)
        .replace("{journeyId}", journeyId),
      {
        credentials: "include",
        method: "DELETE",
      }
    );

    if (!response.ok) {
      const data = await response.text();
      throw new Error(data);
    }

    return response.json();
  },
  "deleteJourneySemantic"
);

export const deleteJourneyStep = loggerWrapper(
  async ({ journeyId, stepId }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_journey_step`
      ]
        .replace("{step}", stepId)
        .replace("{journeyId}", journeyId),
      {
        credentials: "include",
        method: "DELETE",
      }
    );

    if (!response.ok) {
      const data = await response.text();
      throw new Error(data);
    }

    return response.json();
  },
  "deleteJourneyStep"
);

export const uploadFiles = loggerWrapper(
  // can extract also "name" here, if needed
  async ({ body, customPathInfo = null }) => {
    const zip = new JSZip();

    let basePath = "";
    if (customPathInfo.journeyId) {
      // const filename = name.split(".").slice(0, -1).join(".");
      basePath = `dyn/${customPathInfo.journeyId}`;
    } else {
      if (!customPathInfo.cid) {
        throw new Error("missing input data");
      }
      // const datetime = format(new Date(), "yyMMddHHmmss");
      basePath = `cid/dyn/${customPathInfo.cid}`;
    }

    const data = await zip.loadAsync(body);

    const htmlFile = Object.values(data.files).find(
      ({ name, dir }) =>
        !dir && name.toLowerCase().endsWith(".html") && !name.includes("/")
    );

    if (!htmlFile) {
      throw new Error("zip hasn't html file inside in the root!");
    }

    // call upload-files to get the array of {url, uploadUrl},
    // where uploadUrl is presigned upload url; url is the web url to fetch the content after upload
    const fileNamesArr = Object.values(data.files)
      .filter(({ dir }) => !dir)
      .map(({ name }) => {
        return name;
      });

    const requestUploadPayload = {
      basePath,
      fileNamesArr,
    };
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_upload_files`
      ],
      {
        body: JSON.stringify(requestUploadPayload),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );
    const filesUploadInfo = await response.json();
    // {baseUrl, uploadUrls: { fileName: {uploadUrl, contentType}, ...}}
    // baseUrl is the url to the uploaded folder
    // use baseUrl+"fileName" to access he individual file

    const resultUp = await Promise.all(
      Object.values(data.files)
        .filter(({ dir }) => !dir)
        .map(async ({ name }) => {
          const body = await zip.file(name).async("ArrayBuffer");
          return await fetch(filesUploadInfo.uploadUrls[name].uploadUrl, {
            body,
            headers: {
              "Content-Type": filesUploadInfo.uploadUrls[name].contentType,
            },
            method: "PUT",
          });
        })
    );
    if (resultUp.find((r) => r?.status !== 200)) {
      throw new Error("Error uploading some of the files");
    }

    return {
      url: `${filesUploadInfo.baseUrl}/${htmlFile.name}`,
    };
  },
  "uploadFiles"
);

export const uploadFile = loggerWrapper(
  async ({ body, type, customPathInfo }) => {
    let buffer = body;

    if (typeof body === "string") {
      const result = await fetch(body);
      buffer = await result.arrayBuffer();
    }

    let basePath = "";
    if (customPathInfo.journeyId) {
      basePath = `stc/${customPathInfo.journeyId}`;
    } else {
      if (customPathInfo.cmp) {
        basePath = `cmp/stc/${customPathInfo.cmp}`;
      } else {
        if (!customPathInfo.cid) {
          throw new Error("missing input data");
        }
        basePath = `cid/stc/${customPathInfo.cid}`;
      }
    }

    // Commented: now wtoring image as is, just with file size check
    // if ([Jimp.MIME_JPEG, Jimp.MIME_PNG].includes(type)) {
    //   const image = await Jimp.read(buffer);
    //   buffer = await image
    //     .resize(600, Jimp.AUTO)
    //     .quality(75)
    //     .getBufferAsync(type);
    // }

    const requestUploadPayload = {
      basePath,
      type,
    };
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_upload_file`
      ],
      {
        body: JSON.stringify(requestUploadPayload),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    const { url, uploadUrl } = await response.json();

    await fetch(uploadUrl, {
      body: buffer,
      headers: {
        "Content-Type": type,
      },
      method: "PUT",
    });

    return { url };
  },
  "uploadFile"
);

export const deleteFiles = loggerWrapper(
  async ({ mode, toBeDeletedUrl, customPathInfo = null }) => {
    const requestDeletionPayload = { customPathInfo, mode, toBeDeletedUrl };
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_files`
      ],
      {
        body: JSON.stringify(requestDeletionPayload),
        credentials: "include",
        method: "POST",
      }
    );
    const filesDeletionResponse = await response.json();
    // console.log("filesDeletionResponse " + JSON.stringify(filesDeletionResponse));
    if (!filesDeletionResponse || !filesDeletionResponse?.Deleted.length) {
      throw new Error("There was a problem deleting the file");
    }

    return "Ok";
  },
  "deleteFiles"
);

export const createJourney = loggerWrapper(async (journey) => {
  const response = await fetch(
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_create_journey`
    ].replace("{id}", journey.id),
    {
      body: JSON.stringify(journey),
      credentials: "include",
      method: "POST",
    }
  );

  return response.json();
}, "createJourney");

export const updateJourney = loggerWrapper(
  handleResponseWrapper(async (journey) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_update_journey`
      ].replace("{id}", journey.id),
      {
        body: JSON.stringify(journey),
        credentials: "include",
        method: "PUT",
      }
    );

    return response;
  }),
  "updateJourney"
);

export const listJourneyTriggers = loggerWrapper(async ({ journeyId }) => {
  try {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_journey_triggers`
      ].replace("{journeyId}", journeyId),
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response.json();
  } catch (_) {
    return [];
  }
}, "listJourneyTriggers");

export const updateJourneyTrigger = loggerWrapper(
  async (journeyId, trigger) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_update_journey_trigger`
      ]
        .replace("{journeyId}", journeyId)
        .replace("{id}", trigger.id),
      {
        body: JSON.stringify(trigger),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    if (!response.ok) {
      const data = await response.text();
      throw new Error(data);
    }

    return response.json();
  },
  "updateJourneyTrigger"
);

export const createJourneyTrigger = loggerWrapper(
  async (journeyId, trigger) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_create_journey_trigger`
      ].replace("{journeyId}", journeyId),
      {
        body: JSON.stringify(trigger),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    if (!response.ok) {
      const data = await response.text();
      throw new Error(data);
    }

    return response.json();
  },
  "createJourneyTrigger"
);

export const deleteJourneyTrigger = loggerWrapper(async (journeyId, id) => {
  await fetch(
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_journey_trigger`
    ]
      .replace("{journeyId}", journeyId)
      .replace("{id}", id),
    {
      credentials: "include",
      method: "DELETE",
    }
  );
}, "deleteJourneyTrigger");

export const listTriggers = loggerWrapper(async () => {
  try {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_triggers`
      ],
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response.json();
  } catch (_) {
    return [];
  }
}, "listTriggers");

export const listUIConfigs = loggerWrapper(async () => {
  try {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_ui_configs`
      ],
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response.json();
  } catch (_) {
    return {};
  }
}, "listUIConfigs");

export const copyJourney = loggerWrapper(async ({ journeyId, locale }) => {
  const response = await fetch(
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_copy_journey`
    ]
      .replace("{id}", journeyId)
      .replace("{locale}", locale),
    {
      credentials: "include",
      method: "POST",
    }
  );

  return response.json();
}, "copyJourney");

// this is still calling partner dashboard api; removing this feature temporarily
// export const listCompanyUsers = loggerWrapper(async ({ cid }) => {
//

//   const response = await fetch(
//     `https://${process.env.REACT_APP_account_endpoints_partners_api}/companies/${cid}/users`,
//     {
//       headers: {
//         Authorization: `Bearer ${idToken.jwtToken}`,
//       },
//       method: "GET",
//     }
//   );

//   return response.json();
// }, "listCompanyUsers");

export const listCompanies = loggerWrapper(async ({ cid }) => {
  const response = await fetch(
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_companies`
    ],
    {
      credentials: "include",
      method: "GET",
    }
  );

  return response.json();
}, "listCompanies");

export const listMyInfo = loggerWrapper(async () => {
  //

  const response = await fetch(
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_my_info`
    ],
    {
      credentials: "include",
      // headers: {
      //   Authorization: `Bearer ${idToken.jwtToken}`,
      // },
      method: "GET",
    }
  );

  return response.json();
}, "listMyInfo");

// setTimeout(() => getMyCompany(), 5000);

// export const getTriggersAppLink = loggerWrapper(async () => {
//   const creds = Auth.essentialCredentials(await Auth.currentCredentials());
//   const s3 = new AWS.S3(creds);
//   const bucketName =
//     process.env[
//       `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_installers_bucket_name`
//     ];
//   const accountName =
//     process.env[
//       `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_account_name`
//     ];

//   const payload = await s3
//     .listObjectsV2({
//       Bucket: bucketName,
//       Prefix: `installers/${accountName}/`,
//     })
//     .promise();

//   const triggersAppRegex = /.+\/hi-triggers-creation-tool-.+/g;
//   const triggersApp = orderBy(
//     payload.Contents.filter((installer) =>
//       installer.Key.match(triggersAppRegex)
//     ),
//     ["LastModified"],
//     ["desc"]
//   )[0];
//   return s3.getSignedUrlPromise("getObject", {
//     Bucket: bucketName,
//     Expires: 86400,
//     Key: triggersApp.Key,
//   });
// }, "getTriggersAppLink");

export const createCompany = loggerWrapper(
  handleResponseWrapper(async (company) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_create_company`
      ],
      {
        body: JSON.stringify(company),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );
    return response;
  }),
  "createCompany"
);

export const pbiGetConfigCall = loggerWrapper(
  async ({ elementOfInterest, reportSelector }) => {
    const url = process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_get_pbi_embed_token_lambda`
    ]
      .replace("{reportSelector}", reportSelector)
      .replace("{elementOfInterest}", elementOfInterest);
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
    });

    if (!response.ok) {
      const data = await response.text();
      throw new Error(data);
    }

    return response.json();
  },
  "pbiGetConfigCall"
);

export const authRefresh = loggerWrapper(async () => {
  const url =
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_auth_refresh`
    ];
  const response = await fetch(url, {
    credentials: "include",
    method: "POST",
  });

  return response.json();
}, "authRefresh");

export const authSignOut = loggerWrapper(async () => {
  const url =
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_auth_signout`
    ];
  const response = await fetch(url, {
    credentials: "include",
    method: "POST",
  });

  return response.json();
}, "authSignOut");

export const listLinkedJourneys = loggerWrapper(async (journeyId) => {
  const response = await fetch(
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_linked_journeys`
    ].replace("{journeyId}", journeyId),
    {
      credentials: "include",
      method: "GET",
    }
  );

  if (!response.ok) {
    const data = await response.text();
    throw new Error(data);
  }

  return response.json();
}, "linkJourneys");

export const linkJourneys = loggerWrapper(
  async ({ journeyAId, journeyBId }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_link_journeys`
      ]
        .replace("{journeyAId}", journeyAId)
        .replace("{journeyBId}", journeyBId),
      {
        credentials: "include",
        method: "POST",
      }
    );

    if (!response.ok) {
      const data = await response.text();
      throw new Error(data);
    }

    return response.json();
  },
  "linkJourneys"
);

export const unlinkJourneys = loggerWrapper(
  async ({ journeyAId, journeyBId }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_unlink_journeys`
      ]
        .replace("{journeyAId}", journeyAId)
        .replace("{journeyBId}", journeyBId),
      {
        credentials: "include",
        method: "DELETE",
      }
    );

    if (!response.ok) {
      const data = await response.text();
      throw new Error(data);
    }

    return response.json();
  },
  "unlinkJourneys"
);

export const listJourneyGroups = loggerWrapper(
  async ({ journeyId, locale }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_journey_groups`
      ]
        .replace("{id}", journeyId)
        .replace("{locale}", locale),
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (!response.ok) {
      const data = await response.text();
      throw new Error(data);
    }

    return response.json();
  },
  "listGroups"
);

export const deleteJourney = loggerWrapper(
  handleResponseWrapper(async ({ journeyId, password }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_journey`
      ].replace("{journeyId}", journeyId),
      {
        body: JSON.stringify({
          password,
        }),
        credentials: "include",
        method: "DELETE",
      }
    );

    return response;
  }),
  "deleteJourney"
);

export const copySemanticFlow = loggerWrapper(
  handleResponseWrapper(async ({ journeyId, flowId, formData }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_copy_semantic_flow`
      ]
        .replace("{journeyId}", journeyId)
        .replace("{flowId}", flowId),
      {
        body: JSON.stringify(formData),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    return response;
  }),
  "createJourneyGroup"
);

export const createJourneyGroup = loggerWrapper(
  handleResponseWrapper(async ({ journeyId, ...group }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_create_journey_group`
      ].replace("{journeyId}", journeyId),
      {
        body: JSON.stringify(group),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    return response;
  }),
  "createJourneyGroup"
);

export const updateJourneyGroup = loggerWrapper(
  handleResponseWrapper(async ({ id, ...group }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_update_journey_group`
      ].replace("{id}", id),
      {
        body: JSON.stringify(group),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    return response;
  }),
  "updateJourneyGroup"
);

export const deleteJourneyGroup = loggerWrapper(
  handleResponseWrapper(async ({ id }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_journey_group`
      ].replace("{id}", id),
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
      }
    );

    return response;
  }),
  "deleteJourneyGroup"
);

export const listSpecialLinks = loggerWrapper(
  handleResponseWrapper(async ({ all }) => {
    const response = await fetch(
      `${
        process.env[
          `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_special_links`
        ]
      }${all ? "?all=true" : ""}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "listSpecialLinks"
);

export const createSpecialLink = loggerWrapper(
  handleResponseWrapper(async (specialLink) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_create_special_link`
      ],
      {
        body: JSON.stringify(specialLink),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    return response;
  }),
  "createSpecialLink"
);

export const updateSpecialLink = loggerWrapper(
  handleResponseWrapper(async ({ id, ...specialLink }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_update_special_link`
      ].replace("{id}", id),
      {
        body: JSON.stringify(specialLink),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    return response;
  }),
  "updateSpecialLink"
);

export const deleteSpecialLink = loggerWrapper(
  handleResponseWrapper(async ({ id }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_special_link`
      ].replace("{id}", id),
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
      }
    );

    return response;
  }),
  "deleteSpecialLink"
);

export const listTipGroups = loggerWrapper(
  handleResponseWrapper(async ({ all }) => {
    const response = await fetch(
      `${
        process.env[
          `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_tip_groups`
        ]
      }${all ? "?all=true" : ""}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "listTipGroups"
);

export const createTipGroup = loggerWrapper(
  handleResponseWrapper(async (tipGroup) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_create_tip_group`
      ],
      {
        body: JSON.stringify(tipGroup),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    return response;
  }),
  "createTipGroup"
);

export const updateTipGroup = loggerWrapper(
  handleResponseWrapper(async ({ id, ...tipGroup }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_update_tip_group`
      ].replace("{id}", id),
      {
        body: JSON.stringify(tipGroup),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    return response;
  }),
  "updateTipGroup"
);

export const deleteTipGroup = loggerWrapper(
  handleResponseWrapper(async ({ id }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_tip_group`
      ].replace("{id}", id),
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
      }
    );

    return response;
  }),
  "deleteTipGroup"
);

export const listJourneyTriggerConfigs = loggerWrapper(
  handleResponseWrapper(async ({ journeyId }) => {
    const response = await fetch(
      `${process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_journey_trigger_configs`
      ].replace("{journeyId}", journeyId)}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "listJourneyTriggerConfigs"
);

export const updateJourneyTriggerConfig = loggerWrapper(
  handleResponseWrapper(async ({ journeyId, stimulusId, ...body }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_update_journey_trigger_config`
      ]
        .replace("{id}", stimulusId)
        .replace("{journeyId}", journeyId),
      {
        body: JSON.stringify(body),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    return response;
  }),
  "updateJourneyTriggerConfig"
);

export const listJourneySemanticsWithEmptyCTALink = loggerWrapper(
  handleResponseWrapper(async ({ journeyId }) => {
    const response = await fetch(
      `${process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_journey_semantics_with_empty_cta_link`
      ].replace("{journeyId}", journeyId)}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "listJourneySemanticsWithEmptyCTALink"
);

export const listFlowRelations = loggerWrapper(
  handleResponseWrapper(async ({ journeyId, locale }) => {
    const response = await fetch(
      `${process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_flow_relations`
      ]
        .replace("{journeyId}", journeyId)
        .replace("{locale}", locale)}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "listFlowRelations"
);

export const createFlowRelation = loggerWrapper(
  handleResponseWrapper(async ({ journeyId, flowId, relationId, type }) => {
    const response = await fetch(
      `${process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_create_flow_relation`
      ]
        .replace("{journeyId}", journeyId)
        .replace("{flowId}", flowId)}`,
      {
        body: JSON.stringify({ relationId, type }),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    return response;
  }),
  "createFlowRelation"
);

export const deleteFlowRelation = loggerWrapper(
  handleResponseWrapper(async ({ journeyId, flowId, relationId }) => {
    const response = await fetch(
      `${process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_flow_relation`
      ]
        .replace("{journeyId}", journeyId)
        .replace("{flowId}", flowId)
        .replace("{relationId}", relationId)}`,
      {
        credentials: "include",
        method: "DELETE",
      }
    );

    return response;
  }),
  "createFlowRelation"
);

export const listScheduledSlots = loggerWrapper(
  handleResponseWrapper(async ({ journeyId }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_scheduled_slots`
      ].replace("{journeyId}", journeyId),
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "listScheduledSlots"
);

export const createScheduledSlot = loggerWrapper(
  handleResponseWrapper(async ({ scheduledSlot, journeyId }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_create_scheduled_slot`
      ].replace("{journeyId}", journeyId),
      {
        body: JSON.stringify(scheduledSlot),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    return response;
  }),
  "createScheduledSlot"
);

export const updateScheduledSlot = loggerWrapper(
  handleResponseWrapper(async ({ id, ...scheduledSlot }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_update_scheduled_slot`
      ].replace("{id}", id),
      {
        body: JSON.stringify(scheduledSlot),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    return response;
  }),
  "updateScheduledSlot"
);

export const deleteScheduledSlot = loggerWrapper(
  handleResponseWrapper(async ({ id }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_scheduled_slot`
      ].replace("{id}", id),
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
      }
    );

    return response;
  }),
  "deleteScheduledSlot"
);

export const listConfigurations = loggerWrapper(
  handleResponseWrapper(async () => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_configurations`
      ],
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "listConfigurations"
);

export const getConfiguration = loggerWrapper(
  handleResponseWrapper(async ({ cid }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_get_configuration`
      ].replace("{cid}", cid),
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "getConfiguration"
);

export const createConfiguration = loggerWrapper(
  handleResponseWrapper(async (configuration) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_create_configuration`
      ],
      {
        body: JSON.stringify(configuration),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    return response;
  }),
  "createConfiguration"
);

export const updateConfiguration = loggerWrapper(
  handleResponseWrapper(async ({ cid, ...configuration }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_update_configuration`
      ].replace("{cid}", cid),
      {
        body: JSON.stringify(configuration),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    return response;
  }),
  "updateConfiguration"
);

export const deleteConfiguration = loggerWrapper(
  handleResponseWrapper(async ({ cid }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_configuration`
      ].replace("{cid}", cid),
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
      }
    );

    return response;
  }),
  "deleteConfiguration"
);

export const listPrompts = loggerWrapper(
  handleResponseWrapper(async () => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_prompts`
      ],
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "listPrompts"
);

export const createPrompt = loggerWrapper(
  handleResponseWrapper(async (prompt) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_copilotprompt`
      ],
      {
        body: JSON.stringify(prompt),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    return response;
  }),
  "createPrompt"
);

export const updatePrompt = loggerWrapper(
  handleResponseWrapper(async ({ id, ...prompt }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_copilotprompt`
      ].replace("{id}", id) + `?id=${id}`,
      {
        body: JSON.stringify(prompt),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    return response;
  }),
  "updatePrompt"
);

export const deletePrompt = loggerWrapper(
  handleResponseWrapper(async ({ id }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_copilotprompt`
      ].replace("{id}", id) + `?id=${id}`,
      {
        body: JSON.stringify({}),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
      }
    );

    return response;
  }),
  "deletePrompt"
);

export const listTools = loggerWrapper(
  handleResponseWrapper(async () => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_tools`
      ],
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "listTools"
);

export const createTool = loggerWrapper(
  handleResponseWrapper(async (tool) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_copilottool`
      ],
      {
        body: JSON.stringify(tool),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    return response;
  }),
  "createTool"
);

export const updateTool = loggerWrapper(
  handleResponseWrapper(async ({ id, ...tool }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_copilottool`
      ].replace("{id}", id) + `?id=${id}`,
      {
        body: JSON.stringify(tool),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    return response;
  }),
  "updateTool"
);
export const deleteTool = loggerWrapper(
  handleResponseWrapper(async ({ id }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_copilottool`
      ].replace("{id}", id) + `?id=${id}`,
      {
        body: JSON.stringify({}),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
      }
    );

    return response;
  }),
  "deleteTool"
);

export const listTags = loggerWrapper(
  handleResponseWrapper(async () => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_tags`
      ],
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "listTags"
);

export const createTag = loggerWrapper(
  handleResponseWrapper(async (tag) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_copilottag`
      ],
      {
        body: JSON.stringify(tag),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );

    return response;
  }),
  "createTag"
);

export const updateTag = loggerWrapper(
  handleResponseWrapper(async ({ id, ...tag }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_copilottag`
      ].replace("{id}", id) + `?id=${id}`,
      {
        body: JSON.stringify(tag),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    return response;
  }),
  "updateTag"
);

export const deleteTag = loggerWrapper(
  handleResponseWrapper(async ({ id }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_copilottag`
      ].replace("{id}", id) + `?id=${id}`,
      {
        body: JSON.stringify({}),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
      }
    );

    return response;
  }),
  "deleteTag"
);

export const listUsers = loggerWrapper(
  handleResponseWrapper(async () => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_users`
      ],
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "listUsers"
);

export const getUser = loggerWrapper(
  handleResponseWrapper(async ({ id }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_get_user`
      ].replace("{id}", id),
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "getUser"
);

export const getCompany = loggerWrapper(
  handleResponseWrapper(async ({ id }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_get_company`
      ].replace("{id}", id),
      {
        credentials: "include",
        method: "GET",
      }
    );

    return response;
  }),
  "getCompany"
);

export const createUser = loggerWrapper(
  handleResponseWrapper(async (user) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_create_user`
      ],
      {
        body: JSON.stringify(user),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );
    return response;
  }),
  "createUser"
);

export const updateUser = loggerWrapper(
  handleResponseWrapper(async ({ id, ...user }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_update_user`
      ].replace("{id}", id),
      {
        body: JSON.stringify(user),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    return response;
  }),
  "updateUser"
);

export const updateCompany = loggerWrapper(
  handleResponseWrapper(async ({ id, ...company }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_update_company`
      ].replace("{id}", id),
      {
        body: JSON.stringify(company),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
      }
    );

    return response;
  }),
  "updateCompany"
);

export const deleteUser = loggerWrapper(
  handleResponseWrapper(async ({ id }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_user`
      ].replace("{id}", id),
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
      }
    );

    return response;
  }),
  "deleteUser"
);

export const deleteCompany = loggerWrapper(
  handleResponseWrapper(async ({ id }) => {
    const response = await fetch(
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_delete_company`
      ].replace("{id}", id),
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
      }
    );

    return response;
  }),
  "deleteCompany"
);

const addSearchParams = (url, params = {}) =>
  new URL(
    `${url.origin}${url.pathname}?${new URLSearchParams([
      ...Array.from(url.searchParams.entries()),
      ...Object.entries(params),
    ])}`
  );

const callApi = async (
  { path, method = "GET", body } = {},
  pathParams = {},
  searchParams = {}
) => {
  let url =
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_${path}`
    ];

  if (!url) {
    console.error(
      `SSM parameter "/service/${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard/endpoints/${path}" is missed`
    );
    return;
  }

  Object.entries(pathParams).forEach(([key, value]) => {
    url = url.replace(`{${key}}`, value);
  });

  if (Object.keys(searchParams).length !== 0) {
    url = addSearchParams(new URL(url), searchParams).toString();
  }

  return loggerWrapper(
    handleResponseWrapper(async () => {
      const response = await fetch(url, {
        credentials: "include",
        headers: {
          ...(["POST", "PATCH", "PUT"].includes(method.toUpperCase()) &&
            body && {
              "Content-Type": "application/json",
            }),
        },
        method,
        ...(["POST", "PATCH", "PUT"].includes(method.toUpperCase()) &&
          body && {
            body: JSON.stringify(body),
          }),
      });

      return response;
    }),
    camelCase(path)
  )();
};

// Note: this was calling and getting data, but setUserStatePath was called (probably earlier) setting undefined
// export const listUserStatePaths = async (searchParams = {}) => {
//   callApi({ path: "list_user_state_paths" }, {}, searchParams);
// };

export const listUserStatePaths = loggerWrapper(
  handleResponseWrapper(async (searchParams = {}) => {
    let url = `${
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_user_state_paths`
      ]
    }`;

    if (Object.keys(searchParams).length !== 0) {
      url = addSearchParams(new URL(url), searchParams).toString();
    }

    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
    });

    return response;
  }),
  "listUserStatePaths"
);

export const createUserStatePath = async (body) =>
  callApi({ path: "create_user_state_path", body, method: "POST" });

export const updateUserStatePath = async ({ name, ...body }) =>
  callApi(
    { body, method: "PATCH", path: "update_user_state_path" },
    { id: name }
  );

export const deleteUserStatePath = async ({ id }) =>
  callApi({ method: "DELETE", path: "delete_user_state_path" }, { id });

export const listSurveys = async ({ journeyId }) =>
  callApi({ path: "list_surveys" }, { journeyId });

export const listAllSurveysForUser = loggerWrapper(
  handleResponseWrapper(async ({ journeyId }) => {
    let url = `${
      process.env[
        `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_list_surveys`
      ]
    }?includeAll=true`;

    if (!url) {
      console.error(
        `SSM parameter "/service/${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard/endpoints/list_surveys" is missed`
      );
      return;
    }

    Object.entries({ journeyId }).forEach(([key, value]) => {
      url = url.replace(`{${key}}`, value);
    });

    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
    });

    return response;
  }),
  "list_surveys"
);

export const getSurvey = async ({ journeyId, id }) =>
  callApi({ path: "get_survey", method: "GET" }, { journeyId, id });

export const createSurvey = async ({ journeyId, ...body }) =>
  callApi({ path: "create_survey", body, method: "POST" }, { journeyId });

export const updateSurvey = async ({ id, ...body }) =>
  callApi({ path: "update_survey", body, method: "PATCH" }, { id });

export const deleteSurvey = async ({ id }) =>
  callApi({ path: "delete_survey", method: "DELETE" }, { id });

export const getTypeform = async ({ id, integrationId }) =>
  callApi({ path: "get_typeform", method: "GET" }, { id, integrationId });

export const getTypeforms = async ({ integrationId }) =>
  callApi({ path: "get_typeforms", method: "GET" }, { id: integrationId });

export const configureTypeform = async ({ id, integrationId }) =>
  callApi(
    { path: "configure_typeform", method: "POST" },
    { id, integrationId }
  );

export const listIntegrations = async () =>
  callApi({ path: "list_integrations", method: "GET" });

export const createIntegration = async (integration) =>
  callApi({ path: "create_integration", method: "POST", body: integration });

export const exportImage = async (exportImageRequest) =>
  callApi({
    body: exportImageRequest,
    method: "POST",
    path: "export_image",
  });

export const deleteIntegration = async (id) =>
  callApi({ path: "delete_integration", method: "DELETE" }, { id });

export const updateIntegration = async ({ id, ...integration }) =>
  callApi(
    { path: "update_integration", method: "PATCH", body: integration },
    { id }
  );

export const validateIframeUrl = async (body) =>
  callApi({ path: "validate_iframe_url", body, method: "POST" });

export const listWelfareSelector = async (selector) =>
  callApi({ path: "welfare_list_selectors", method: "GET" }, {}, { selector });

export const getBenefit = async (id) =>
  callApi({ path: "welfare_list_benefits", method: "GET" }, {}, { id });

// starting from journeyId, retrieve related welfare entity
export const getAssociatedWelfareEntity = async ({ journey_id }) =>
  callApi(
    { method: "GET", path: "welfare_list_legalentityjourneys" },
    {},
    { journeyId: journey_id }
  );

export const listEntityBenefits = async ({ entity }) =>
  callApi({ method: "GET", path: "welfare_list_benefits" }, {}, { entity });

export const listJourneyBenefits = async ({ journey_id }) => {
  const jentities = (await getAssociatedWelfareEntity({ journey_id })) ?? [];
  const entity = jentities?.[0]?.legalentity_id;
  return entity
    ? {
        entity: jentities?.[0]?.legalentity_name,
        entityBenefits: await listEntityBenefits({ entity }),
      }
    : { entityBenefits: [] };
};

export const listAllBenefits = async () =>
  callApi({ path: "welfare_list_benefits", method: "GET" });

export const listWelfareCategories = async () =>
  callApi({ path: "welfare_list_categories", method: "GET" });

export const listWelfareSubcategories = async (categoryId) =>
  callApi(
    { path: "welfare_list_subcategories", method: "GET" },
    {},
    categoryId !== null ? { categoryId } : {}
  );

export const createWelfareBenefit = async (body) =>
  callApi({ path: "welfares_write_benefit", body, method: "POST" });

export const updateWelfareBenefit = async ({ id, ...body }) =>
  callApi(
    { path: "welfares_write_benefit", body, method: "PATCH" },
    {},
    { id }
  );

export const listAngeliniUsers = async (body) =>
  callApi({ path: "welfare_list_angeliniusers", body, method: "POST" });

export const getNews = async (id) =>
  callApi({ path: "welfare_list_news", method: "GET" }, {}, { id });

export const listAllNews = async () =>
  callApi({ path: "welfare_list_news", method: "GET" });

export const createWelfareNews = async (body) =>
  callApi({ path: "welfares_write_news", body, method: "POST" });

export const updateWelfareNews = async ({ id, ...body }) =>
  callApi({ path: "welfares_write_news", body, method: "PATCH" }, {}, { id });

export const listWelfareBookings = async () =>
  callApi({ path: "welfare_list_bookings", method: "GET" });

export const createWelfareBooking = async (body) =>
  callApi({ path: "welfares_write_bookings", body, method: "POST" });

export const updateWelfareBooking = async ({ id, ...body }) =>
  callApi(
    { path: "welfares_write_bookings", body, method: "PATCH" },
    {},
    { id }
  );
export const listBookingEventsConditions = async (userid, bookingid) =>
  callApi(
    { path: "welfare_list_booking_events_conditions", method: "GET" },
    {},
    { userid, bookingid }
  );
