import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import Loader from "../shared/Loader";
import { useModals } from "../../hooks/useModals";
import { useAppContext } from "../../api/AppContext";
import OutlinedButton from "../OutlinedButton";

const NewsList = () => {
  const [isNewsLoading, setIsNewsLoading] = useState(true);
  const [isCopyNewsLoading, setIsCopyNewsLoading] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const modals = useModals();
  const history = useHistory();
  const { fetchAllNews, news, updateNews, deleteNews } = useAppContext();

  useEffect(() => {
    fetchAllNews();
  }, [fetchAllNews]);

  useEffect(() => {
    if (news.length) {
      setNewsList(news);
      setIsNewsLoading(false);
      setIsCopyNewsLoading(false);
    }
  }, [news]);

  const handleCreate = useCallback(() => {
    history.push(`/news/new/start`, {
      isNew: true,
    });
  }, [history]);

  const handleEdit = useCallback(
    ({ id }) => {
      history.push(`/news/${id}`);
    },
    [history]
  );

  const handleDelete = useCallback(
    (newsItem) => {
      modals.openConfirmation({
        onConfirm: async () => {
          await deleteNews({
            id: newsItem.id,
            isDeleted: true,
          });
          fetchAllNews();
        },
        text: `You want to delete news "${newsItem.text_default.title}"`,
      });
    },
    [deleteNews, modals, fetchAllNews]
  );

  const handlePublish = useCallback(
    async (newsItem) => {
      const updatedNews = {
        id: newsItem.id,
        isPublished: !newsItem.ispublished,
      };
      await updateNews(updatedNews);
      fetchAllNews();
    },
    [updateNews, fetchAllNews]
  );

  const buildNewsObject = useCallback((newsItem) => {
    return {
      isNew: true,
      cmp: "9aa46b2c-5fa6-4f0c-839d-35527dd19386",
      image: newsItem.image,
      text_default: newsItem.text_default,
      text_en: newsItem.text_en,
      isPublished: false,
      startDate: new Date(newsItem.startdate),
      expirationDate: new Date(newsItem.expirationdate),
      conditions: {
        entity: newsItem.legalentity_id,
        contractTypes: newsItem.contracttypenames,
        employeeTypes: newsItem.employeetypenames,
        employeeRoles: newsItem.employeerolenames,
        officeTypes: newsItem.officetypenames,
        officeLocations: newsItem.officelocationnames,
        forUserIds: [],
        notForUserIds: [],
      },
    };
  }, []);

  const handleCopy = useCallback(
    (newsItem) => {
      modals.openModal(modals.modalTypes.CopyWelfare, {
        onConfirm: async ({ entity }) => {
          const newNews = buildNewsObject(newsItem);
          newNews.conditions.entity = entity;
          setIsCopyNewsLoading(true);
          await updateNews(newNews);
          fetchAllNews();
        },
      });
    },
    [modals, buildNewsObject, updateNews, fetchAllNews]
  );

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      {isNewsLoading || isCopyNewsLoading ? (
        <Loader />
      ) : (
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="h6">News</Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="body2">
                  Use this section to configure each news item
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              endIcon={<AddIcon />}
              variant="contained"
              onClick={handleCreate}
            >
              Add news
            </Button>
          </Grid>
          <Grid item>
            <List>
              {newsList.map((newsItem, index) => (
                <ListItem
                  key={newsItem.id}
                  divider={index < newsList.length - 1}
                >
                  <ListItemText
                    primary={newsItem.text_default?.title}
                    secondary={newsItem.text_default?.subtitle}
                  />
                  <ListItemSecondaryAction
                    style={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Tooltip
                      title={
                        newsItem.ispublished
                          ? "Published (click to unpublish)"
                          : "Unpublished (click to publish)"
                      }
                    >
                      <IconButton onClick={() => handlePublish(newsItem)}>
                        {newsItem.ispublished ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Copy">
                      <span style={{ marginRight: "1rem" }}>
                        <OutlinedButton onClick={() => handleCopy(newsItem)}>
                          Copy
                        </OutlinedButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <span>
                        <OutlinedButton onClick={() => handleEdit(newsItem)}>
                          Edit
                        </OutlinedButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleDelete(newsItem)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default NewsList;
