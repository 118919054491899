import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import CommentIcon from "@material-ui/icons/Comment";
import SearchIcon from "@material-ui/icons/Search";
import Dialog from "./shared/Dialog";
import DialogActions from "./DialogActions";
import OutlinedButton from "./OutlinedButton";
import useModal from "../hooks/useModal";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";
import { green, red } from "@material-ui/core/colors";
import { DateTimePicker } from "@material-ui/pickers";
import Tooltip from "@material-ui/core/Tooltip";
//import { DateTimePicker, TimePicker } from "@material-ui/pickers";

import {
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import { useAppContext } from "../api/AppContext";
import {
  ClickAwayListener,
  InputAdornment,
  MenuItem,
  Paper,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { useTheme } from "@material-ui/core/styles";
import { DebounceInput } from "react-debounce-input";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  root: {
    "& .Mui-disabled": {
      color: "black",
    },
  },
});
const CustomTitle = styled(DialogTitle)`
  padding-bottom: 0px;
`;

const CustomDialogContent = styled(DialogContent)`
  padding-top: 0px;
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-direction: column;
`;

const CustomDropdown = ({ options, onSelect, loading, values }) => {
  return (
    <Paper
      style={{
        height: "200px",
        maxHeight: "200px",
        overflowY: "auto",
        position: "absolute",
        width: "100%",
        zIndex: 1,
      }}
    >
      {loading ? (
        <LoaderContainer>
          <CircularProgress size={30} />
        </LoaderContainer>
      ) : options.length > 0 ? (
        options.map((option) => (
          <MenuItem
            key={option.id}
            selected={values.some((item) => item.id === option.id)}
            onClick={() => onSelect(option)}
          >
            {option.mail ?? `${option.surname}, ${option.givenname}`}
          </MenuItem>
        ))
      ) : (
        <LoaderContainer>
          Oops.. There&apos;s no result for your search :{"("}
        </LoaderContainer>
      )}
    </Paper>
  );
};

const Search = ({
  onChange,
  value,
  isDisabled,
  isLoading,
  options,
  onSelect,
  selectedValues,
}) => {
  const theme = useTheme();
  const ref = useRef();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClear = useCallback(() => {
    onChange({
      target: {
        value: "",
      },
    });
  }, [onChange]);

  useEffect(() => {
    const listener = (e) => {
      if ((e.key === "f" || e.key === "F") && e.ctrlKey && e.shiftKey) {
        e.preventDefault();
        ref.current?.focus();
      }
    };
    window.addEventListener("keypress", listener);
    return () => {
      window.removeEventListener("keypress", listener);
    };
  }, []);

  const handleSelectOption = (selectedOption) => {
    setShowDropdown(false);
    onSelect(selectedOption);
  };

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickAway = () => {
    setShowDropdown(false);
  };
  //const selectedUser = selectedValues.length > 0 ? selectedValues[0] : null;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ position: "relative" }}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value && (
                  <IconButton size="small" onClick={handleClear}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
            inputComponent: DebounceInput,
            inputProps: {
              debounceTimeout: 500,
            },
          }}
          disabled={isDisabled}
          inputRef={ref}
          margin="none"
          placeholder="Browse a user..."
          //placeholder="Browse a user..."
          size="small"
          style={{
            ...theme.typography.body2,
            width: "100%",
          }}
          value={value}
          variant="outlined"
          onChange={onChange}
          onClick={handleToggleDropdown}
        />
        {value && showDropdown && (
          <CustomDropdown
            loading={isLoading}
            options={options}
            values={selectedValues}
            onSelect={handleSelectOption}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

const validationSchema = yup.object().shape({
  added_user_ids: yup.array(),
});

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    height: 100%;
  }
`;

const SpecificUsersBookingModal = ({
  onConfirm,
  onClose,
  bookingRecord,
  maxperuserperyear,
}) => {
  const {
    isAngeliniUsersLoading,
    fetchAngeliniUsers,
    fetchBookingEventsConditions,
    //bookingEventsConditions,
  } = useAppContext();

  const [searchText, setSearchText] = useState("");
  const [angeliniUsers, setAngeliniUsers] = useState([]);
  const [maxPerUserValue, setMaxPerUserValue] = useState(maxperuserperyear);
  const [commentPopup, setCommentPopup] = useState(null);
  const [currentComment, setCurrentComment] = useState(null);
  const [usedSessions, setUsedSessions] = useState({});
  const [displayCustomFields, setDisplayCustomFields] = useState(false);
  const [displayDeleteComment, setDisplayDeleteComment] = useState(null);
  const [confirmedDeletions, setConfirmedDeletions] = useState({});
  const [deleteCustomLimit, setDeleteCustomLimit] = useState({});
  const [realUserEvents, setRealUserEvents] = useState({});
  const [fakeUserEvents, setFakeUserEvents] = useState({});
  const classes = useStyles();

  useEffect(() => {
    if (bookingRecord?.used_sessions) {
      setUsedSessions(bookingRecord.used_sessions);
    }
  }, [bookingRecord]);

  const toggleDeleteCustom = (userId) => {
    setDeleteCustomLimit((prev) => ({ ...prev, [userId]: !prev[userId] }));
  };

  const handleConfirmDelete = (userId) => {
    formik.setFieldValue(`custommaxperuser.${userId}`, null);
    toggleDeleteCustom(userId);
  };

  const handleCancelDelete = (userId) => {
    toggleDeleteCustom(userId);
  };
  const handleCommentPopupClick = (event, bookingIdx) => {
    event.stopPropagation();
    setCommentPopup(event.currentTarget);
    setCurrentComment(bookingIdx);
  };

  const handleCommentPopupClose = (event) => {
    event.stopPropagation();
    setCommentPopup(null);
    setCurrentComment(null);
  };

  useEffect(() => {
    setMaxPerUserValue(maxperuserperyear);
  }, [maxperuserperyear]);

  const buildUserContainer = (user) => {
    return (
      <Grid
        item
        md={4}
        style={{
          alignItems: "start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        xs={10}
      >
        {`${user.surname || ""}${user.surname && user.givenname ? ", " : ""}${
          user.givenname || ""
        }`}
        <Typography>
          {user.mail.length > 40 ? (
            <Tooltip arrow title={user.mail}>
              <span style={{ cursor: "pointer" }}>
                {user.mail.slice(0, 40) + "..."}
              </span>
            </Tooltip>
          ) : (
            user.mail
          )}
        </Typography>
      </Grid>
    );
  };

  const { handleConfirm } = useModal({
    onClose,
    onConfirm,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      added_user_ids: [],
      customaddreduce: bookingRecord?.customaddreduce || {},
      custommaxperuser: bookingRecord?.custommaxperuser || maxPerUserValue,
    },
    onSubmit: async (values) => {
      await handleConfirm(values);
    },
    validationSchema,
    validator: () => ({}),
  });
  const handleDeleteBooking = useCallback(
    (userId, booking) => (event) => {
      event.stopPropagation();
      setDisplayDeleteComment(booking.id);

      const currentDeletions =
        formik.values.customaddreduce[userId]?.deletions || [];
      formik.setFieldValue(`customaddreduce.${userId}.deletions`, [
        ...currentDeletions,
        {
          comment_delete: "",
          deletedBookingId: booking.id,
          isdeleted: true,
        },
      ]);
    },
    [formik]
  );
  const handleOnSelect = useCallback(
    async (item) => {
      formik.setFieldValue("added_user_ids", [
        { id: item.id, mail: item.mail },
      ]);
      try {
        const data = await fetchBookingEventsConditions(
          item.id,
          bookingRecord.id
        );
        if (data) {
          setUsedSessions(data.used_sessions);
          setRealUserEvents(data.real_user_events[0]);
          setFakeUserEvents(data.fake_user_events[0]);
          formik.setFieldValue("custommaxperuser", data.custommaxperuser);
        }
      } catch (error) {
        console.error("Error fetching booking events conditions:", error);
      }
    },
    [formik, fetchBookingEventsConditions, bookingRecord]
  );
  console.log("used sessions " + usedSessions);
  const handleSearch = useCallback(
    (e) => {
      setSearchText(e.target.value);
    },
    [setSearchText]
  );

  const cancelSearch = () => {
    setSearchText("");
    setAngeliniUsers([]);
  };

  useEffect(() => {
    async function fetchData() {
      const dataObject = { searchtext: searchText };
      const users = await fetchAngeliniUsers(dataObject);
      setAngeliniUsers(users);
    }
    if (searchText && searchText.length > 3) fetchData();
  }, [fetchAngeliniUsers, searchText]);

  const handleCustomMaxChange = (userId) => (event) => {
    const newValue = event.target.value;
    formik.setFieldValue(`custommaxperuser.${userId}`, newValue);
    setMaxPerUserValue(newValue);
  };

  const handleCustomAddReduceChange = (userId) => () => {
    setDisplayCustomFields(true);
    formik.setFieldValue(`customaddreduce.${userId}.additions`, [
      {
        isdeleted: false,
        value: 1,
      },
    ]);
  };
  const handleAddCommentChange = (userId) => (event) => {
    formik.setFieldValue(
      `customaddreduce.${userId}.additions[0].comment_add`,
      event.target.value
    );
  };
  const handleDefaultDate = (userId) => {
    const defaultDate = new Date().toISOString();
    formik.setFieldValue(
      `customaddreduce.${userId}.additions[0].session_date`,
      defaultDate
    );
  };
  const handleSessionDateChange = (userId) => (date) => {
    if (date instanceof Date && !isNaN(date)) {
      const isoDate = date.toISOString();
      formik.setFieldValue(
        `customaddreduce.${userId}.additions[0].session_date`,
        isoDate
      );
    }
  };
  const handleDeleteCommentChange = (userId, deletedBookingId) => (event) => {
    const newComment = event.target.value;
    formik.setFieldValue(
      `customaddreduce.${userId}.deletions`,
      (formik.values.customaddreduce[userId]?.deletions || []).map((deletion) =>
        deletion.deletedBookingId === deletedBookingId
          ? { ...deletion, comment_delete: newComment }
          : deletion
      )
    );
  };
  return (
    <CustomDialog fullWidth open maxWidth="md" style={{ height: "100%" }}>
      <CustomTitle>
        <Box display="flex" justifyContent="space-between">
          Select the person(s) to whom you want to change the booking limit
          <div>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
      </CustomTitle>
      <CustomDialogContent>
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item style={{ width: "100%" }} xs={12}>
              <Search
                isDisabled={false}
                isLoading={isAngeliniUsersLoading}
                options={angeliniUsers.filter(
                  (angeliniUser) =>
                    !formik.values.added_user_ids.some(
                      (addedUser) => angeliniUser.mail === addedUser.mail
                    )
                )}
                selectedValues={formik.values.added_user_ids}
                value={searchText}
                onCancelSearch={cancelSearch}
                onChange={handleSearch}
                onSelect={handleOnSelect}
              />
            </Grid>
            <Grid item style={{ paddingTop: "0px" }} xs={12}>
              <Box
                boxSizing="border-box"
                display="flex"
                flexDirection="column"
                height="100%"
                pb={4}
                pl={4}
                pr={4}
                pt={0}
                width="100%"
              >
                {formik.values.added_user_ids.map((user, idx) => {
                  console.log("realUserEvents:", realUserEvents);
                  console.log("fakeUserEvents:", fakeUserEvents);
                  console.log("current user.id:", user.id);
                  const userEvents = [
                    ...(realUserEvents[user.id] || []).map((event) => ({
                      ...event,
                      isReal: true,
                    })),
                    ...(fakeUserEvents[user.id] || []).map((event) => ({
                      ...event,
                      isReal: false,
                    })),
                  ];
                  console.log("user events " + userEvents);
                  return (
                    <Accordion
                      key={idx}
                      expanded={true}
                      style={{ border: "none", marginTop: "0.5rem" }}
                    >
                      <AccordionSummary>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item lg={5} md={5} sm={6} xs={12}>
                            {buildUserContainer(user)}
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={3}
                            style={{ alignItems: "center", display: "flex" }}
                            xs={12}
                          >
                            <Typography
                              style={{
                                marginRight: "0.5rem",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Used Sessions: {usedSessions[user.id] || 0} /
                            </Typography>
                            <Grid style={{ marginTop: "1.5rem" }}>
                              <TextField
                                size="small"
                                style={{
                                  width: "5rem",
                                }}
                                type="number"
                                value={
                                  formik.values.custommaxperuser[user.id] ===
                                  null
                                    ? maxPerUserValue
                                    : formik.values.custommaxperuser[user.id] ||
                                      maxPerUserValue
                                }
                                variant="outlined"
                                onChange={handleCustomMaxChange(user.id)}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              />
                              <Typography align="center" variant="body2">
                                {formik.values.custommaxperuser[user.id]
                                  ? "Custom"
                                  : "Default"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item lg={3} md={3} sm={3} xs={12}>
                            <Button
                              fullWidth
                              color="primary"
                              style={{
                                marginBottom: "1rem",
                                whiteSpace: "nowrap",
                              }}
                              variant="contained"
                              onClick={handleCustomAddReduceChange(user.id)}
                            >
                              ADD USED SESSIONS
                            </Button>
                            {formik.values.custommaxperuser[user.id] !== null &&
                              (deleteCustomLimit[user.id] ? (
                                <>
                                  <IconButton
                                    style={{ marginLeft: "2rem" }}
                                    onClick={() => handleConfirmDelete(user.id)}
                                  >
                                    <DoneIcon
                                      style={{
                                        color: green[500],
                                        cursor: "pointer",
                                        margin: " -0.5rem 0.5rem 0 0.5rem",
                                        transform: "scale(1.5)",
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => handleCancelDelete(user.id)}
                                  >
                                    <CloseIcon
                                      style={{
                                        color: red[500],
                                        cursor: "pointer",
                                        margin: " -0.5rem 0.5rem 0 0.5rem",
                                        transform: "scale(1.5)",
                                      }}
                                    />
                                  </IconButton>
                                </>
                              ) : (
                                <Button
                                  fullWidth
                                  color="primary"
                                  style={{ whiteSpace: "nowrap" }}
                                  variant="contained"
                                  onClick={() => toggleDeleteCustom(user.id)}
                                >
                                  Delete Custom Limit
                                </Button>
                              ))}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      {displayCustomFields && (
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Grid
                            container
                            alignItems="center"
                            spacing={2}
                            style={{ marginLeft: "1rem" }}
                          >
                            <Grid item sm={4} xs={12}>
                              <TextField
                                fullWidth
                                multiline
                                label="Add Comment"
                                size="medium"
                                variant="outlined"
                                onChange={handleAddCommentChange(user.id)}
                              />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                              <DateTimePicker
                                autoOk
                                disablePast
                                ampm={false}
                                format="dd MMMM yyyy HH:mm"
                                inputVariant="outlined"
                                label="Select Booking Date"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                  />
                                )}
                                value={
                                  formik.values.customaddreduce[user.id]
                                    ?.additions[0]?.session_date ||
                                  (() => {
                                    handleDefaultDate(user.id);
                                  })()
                                }
                                onChange={handleSessionDateChange(user.id)}
                              />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                              <IconButton
                                style={{
                                  color: red[500],
                                  cursor: "pointer",
                                  margin: " -0.5rem 0.5rem 0 0.5rem",
                                  transform: "scale(1.5)",
                                }}
                                onClick={() => setDisplayCustomFields(false)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <AccordionDetails>
                        <Grid container style={{ marginTop: "0rem" }}>
                          <Grid item style={{ marginTop: "0rem" }} xs={12}>
                            {userEvents
                              .filter(
                                (booking) =>
                                  !confirmedDeletions[user.id]?.includes(
                                    booking.id
                                  )
                              )
                              .map((booking, bookingIdx) => (
                                <Grid
                                  key={booking.id}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "0.5rem",
                                  }}
                                >
                                  <Grid
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        textDecoration: booking.isdeleted
                                          ? "line-through"
                                          : "",
                                      }}
                                      variant="body1"
                                    >
                                      {booking.isReal
                                        ? "Real Booking"
                                        : "Manual Booking"}
                                      : Session
                                      {booking.isdeleted ? (
                                        <>
                                          {` deleted by ${
                                            booking.updated_by
                                          } on ${booking.updated_at.substr(
                                            0,
                                            10
                                          )} `}
                                        </>
                                      ) : (
                                        <>
                                          {!booking.isReal
                                            ? " created by "
                                            : " booked by "}
                                          <Tooltip
                                            arrow
                                            title={
                                              !booking.isReal
                                                ? booking.created_by
                                                : booking.customeremailaddress
                                            }
                                          >
                                            <span style={{ cursor: "pointer" }}>
                                              {!booking.isReal
                                                ? booking.created_by.length > 15
                                                  ? booking.created_by.slice(
                                                      0,
                                                      15
                                                    ) + "..."
                                                  : booking.created_by
                                                : booking.customeremailaddress
                                                    .length > 15
                                                ? booking.customeremailaddress.slice(
                                                    0,
                                                    15
                                                  ) + "..."
                                                : booking.customeremailaddress}
                                            </span>
                                          </Tooltip>
                                          {` on ${booking.created_at.substr(
                                            0,
                                            10
                                          )} for ${booking.sessionstart_at.substr(
                                            0,
                                            10
                                          )}`}
                                        </>
                                      )}
                                    </Typography>
                                    {booking.isdeleted && (
                                      <Typography
                                        style={{ marginLeft: "1rem" }}
                                        variant="body2"
                                      >
                                        Created by {booking.created_by} on{" "}
                                        {booking.created_at.substr(0, 10)} for{" "}
                                        {booking.sessionstart_at.substr(0, 10)}
                                      </Typography>
                                    )}
                                  </Grid>
                                  {!booking.isdeleted && (
                                    <IconButton
                                      style={{
                                        marginTop: "-0.75rem",
                                        paddingRight: "0.25rem",
                                      }}
                                      onClick={handleDeleteBooking(
                                        user.id,
                                        booking
                                      )}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                  {(booking.comment_add ||
                                    booking.comment_delete) && (
                                    <>
                                      <IconButton
                                        style={{
                                          marginTop: "-0.5rem",
                                          paddingLeft: "0.25rem",
                                        }}
                                        onClick={(event) =>
                                          handleCommentPopupClick(
                                            event,
                                            bookingIdx
                                          )
                                        }
                                      >
                                        <CommentIcon />
                                      </IconButton>
                                      <Popover
                                        anchorEl={commentPopup}
                                        anchorOrigin={{
                                          horizontal: "left",
                                          vertical: "bottom",
                                        }}
                                        open={currentComment === bookingIdx}
                                        onClose={handleCommentPopupClose}
                                      >
                                        <TextField
                                          autoFocus
                                          disabled
                                          fullWidth
                                          multiline
                                          className={classes.root}
                                          margin="dense"
                                          style={{
                                            color: "black",
                                            padding: "1rem",
                                            width: "300px",
                                          }}
                                          value={
                                            !booking.isdeleted
                                              ? booking.comment_add
                                              : booking.comment_delete
                                          }
                                          variant="outlined"
                                        />
                                      </Popover>
                                    </>
                                  )}
                                  {booking.id === displayDeleteComment && (
                                    <>
                                      <TextField
                                        multiline
                                        label="Add Comment"
                                        size="small"
                                        value={
                                          formik.values.customaddreduce[
                                            user.id
                                          ]?.deletions?.find(
                                            (d) =>
                                              d.deletedBookingId === booking.id
                                          )?.comment_delete || ""
                                        }
                                        variant="outlined"
                                        onChange={handleDeleteCommentChange(
                                          user.id,
                                          booking.id
                                        )}
                                      />
                                      <IconButton>
                                        <DoneIcon
                                          style={{
                                            color: green[500],
                                            cursor: "pointer",
                                            margin: " -0.5rem 0.5rem 0 0.5rem",
                                            transform: "scale(1.5)",
                                          }}
                                          onClick={() => {
                                            setConfirmedDeletions((prev) => ({
                                              ...prev,
                                              [user.id]: [
                                                ...(prev[user.id] || []),
                                                booking.id,
                                              ],
                                            }));
                                            setDisplayDeleteComment(null);
                                          }}
                                        />
                                      </IconButton>
                                      <IconButton>
                                        <CloseIcon
                                          style={{
                                            color: red[500],
                                            cursor: "pointer",
                                            margin: " -0.5rem 0.5rem 0 0.5rem",
                                            transform: "scale(1.5)",
                                          }}
                                          onClick={() => {
                                            const updatedDeletions =
                                              formik.values.customaddreduce[
                                                user.id
                                              ]?.deletions.filter(
                                                (deletion) =>
                                                  deletion.deletedBookingId !==
                                                  booking.id
                                              );
                                            formik.setFieldValue(
                                              `customaddreduce.${user.id}.deletions`,
                                              updatedDeletions
                                            );
                                            setDisplayDeleteComment(null);
                                          }}
                                        />
                                      </IconButton>
                                    </>
                                  )}
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </CustomDialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Confirm
        </Button>
        <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default SpecificUsersBookingModal;
