import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import * as yup from "yup";
import invert from "lodash/invert";
import Stepper from "@material-ui/core/Stepper";
import StepButton from "@material-ui/core/StepButton";
import Step from "@material-ui/core/Step";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import Start from "./steps/Start";
import Requirements from "./steps/Requirements";
import NeedToKnow from "./steps/NeedToKnow";
import Doubts from "./steps/Doubts";
import FirstInfo from "./steps/FirstInformation";
import { useAppContext } from "../../api/AppContext";
import pickBy from "lodash/pickBy";
import isEmpty from "lodash/isEmpty";
import Loader from "../shared/Loader";
import KeyInfo from "./steps/KeyInfo";
import Publish from "./steps/Publish";
import SaveButtons from "../SaveButtons";

const steps = [
  "Let’s get started",
  "Access criteria to benefits",
  "Initial information about benefits",
  'Section "Need to know"',
  'Section "Still doubts?"',
  'Section "Key information"',
  "Confirm and publish",
];

const tabs = {
  start: 0,
  requirement: 1,
  "first-information": 2,
  "need-to-know": 3,
  doubts: 4,
  "key-info": 5,
  publish: 6,
};

const textPrimary =
  "In this section you will be able to post a benefit in the WECARE platform";

const textSecondary =
  "WECARE Benefits is structured in a way to help employees find the information they need to take advantage of your benefits. It also helps you gather information and feedback!";

const textInfo = "To create a benefit you must have the following information:";

const textConditions = [
  "The eligibility requirements for the benefit, so who will use it and who can see it",
  "What Pillar is it part of, People, Health or Family? One, two or all three?",
  "In what category and subcategory will your employees find the benefit?",
  "A description to make it easier to understand the benefit",
  "The steps to activate it and perhaps some documentation to help employees if they have any doubts (FAQs)",
];

const Welfare = ({
  //formik,
  tab,
  isNew,
  welfareSelectors,
  welfareCategories,
  welfareSubcategories,
}) => {
  const formik = useFormikContext();
  const [activeStep, setActiveStep] = useState(tabs[tab] ?? 0);
  const history = useHistory();
  const location = useLocation();

  const handleStep = useCallback(
    (step) => () => {
      history.replace({
        ...location,
        pathname: location.pathname.replace(
          new RegExp(
            `/welfares/${formik.values.isNew ? "new" : formik.values.id}(/.+)?`,
            "g"
          ),
          `/welfares/${formik.values.isNew ? "new" : formik.values.id}/${
            invert(tabs)[step]
          }`
        ),
      });
      setActiveStep(step);
    },
    [formik.values.id, formik.values.isNew, history, location]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid item xs={12}>
        <>
          <div
            style={{
              display: activeStep === 0 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <Start
                textConditions={textConditions}
                textInfo={textInfo}
                textPrimary={textPrimary}
                textSecondary={textSecondary}
              />
            </Grid>
          </div>
          <div
            style={{
              display: activeStep === 1 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <Requirements
                formik={formik}
                welfareSelectors={welfareSelectors}
              />
            </Grid>
          </div>
          <div
            style={{
              display: activeStep === 2 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <FirstInfo
                formik={formik}
                welfareCategories={welfareCategories}
                welfareSubcategories={welfareSubcategories}
              />
            </Grid>
          </div>
          <div
            style={{
              display: activeStep === 3 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <NeedToKnow formik={formik} />
            </Grid>
          </div>
          <div
            style={{
              display: activeStep === 4 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <Doubts formik={formik} />
            </Grid>
          </div>
          <div
            style={{
              display: activeStep === 5 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <KeyInfo formik={formik} />
            </Grid>
          </div>
          <div
            style={{
              display: activeStep === 6 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <Publish
                buttonText={"Click to see the Benefit Preview"}
                previewLink={"https://angelini.habitinspiring.com/detail"}
                title={
                  "Congratulations!!!. Before previewing the actual benefit, make sure to save it."
                }
              />
            </Grid>
          </div>
          <Grid item xs={12}>
            {activeStep === steps.length - 1 ? (
              <Grid item xs={12}>
                <SaveButtons
                  formik={formik}
                  isNew={isNew}
                  isShowSaveErrors={true}
                />
              </Grid>
            ) : (
              <Button
                color="primary"
                style={{ marginTop: "1rem" }}
                variant="outlined"
                onClick={handleStep(activeStep + 1)}
              >
                Next
              </Button>
            )}
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

const WelfareContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true);
  const [benefit, setBenefit] = useState(null);
  const { id, tab } = match.params;
  const { isNew } = location.state || {};
  const {
    fetchBenefit,
    isBenefitLoading,
    updateWelfareBenefit,
    fetchWelfareSelector,
    welfareSelectors,
    isWelfareSelectorsLoading,
    fetchWelfareCategories,
    welfareCategories,
    isWelfareCategoriesLoading,
    fetchWelfareSubcategories,
    welfareSubcategories,
    isWelfareSubcategoriesLoading,
  } = useAppContext();

  useEffect(() => {
    if (!isWelfareSelectorsLoading) {
      const emptyArrays = pickBy(welfareSelectors, (value) => isEmpty(value));

      Promise.all(
        Object.keys(emptyArrays)?.map((elem) => fetchWelfareSelector(elem))
      );
    }
    if (!isWelfareCategoriesLoading && !welfareCategories?.length) {
      fetchWelfareCategories();
    }
    if (!isWelfareSubcategoriesLoading && !welfareSubcategories?.length) {
      fetchWelfareSubcategories();
    }
  }, []);

  useEffect(() => {
    if (!id || isNew || id === "new") {
      setIsLoading(false);
      return;
    }

    fetchBenefit(id).then((resp) => {
      setBenefit(resp);
    });
  }, [id, isNew, fetchBenefit]);

  useEffect(() => {
    setIsLoading(false);
  }, [benefit]);

  const editSchema = yup.object({
    pillars: yup
      .array()
      .min(1, "Pillars: select at least one option")
      .max(3, "Select up to three options")
      .of(yup.string().oneOf(["family", "people", "health"], "Invalid option")),
    category_id: yup.string().required("Category is required"),
    subcategory_id: yup.string().required("Subcategory is required"),
    image: yup.string().required("Cover image is required"),
    previewImage: yup.string().nullable(),
    analytics_name: yup.string().nullable(),
    userCanGiveFeedback: yup.boolean().nullable(),
    viaBookingSystem: yup.boolean().nullable(),
    booking_id: yup.string().nullable(),
    openInWecare: yup.boolean().nullable(),
    userCanSubscribe: yup.boolean().nullable(),
    isPublished: yup.boolean().nullable(),
    freeComemnt: yup.boolean().nullable(),
    questionAboutUsed: yup.boolean().nullable(),
    keyInfo: yup.object({
      contacts: yup.array().nullable(),
      supportContacts: yup.array().nullable(),
      sites: yup.array().when("openInWecare", {
        is: true,
        then: yup
          .array()
          .required("Sites is required when openInWecare is true"),
        otherwise: yup.array().nullable(),
      }),
      usefullLinks: yup.array(),
      manager: yup.string().nullable(),
      expirationDate: yup.date().nullable(),
    }),
    conditions: yup.object({
      contractTypes: yup.array().min(1, "Select at least one contract type"),
      employeeTypes: yup.array().min(1, "Select at least one employee type"),
      employeeRoles: yup.array().min(1, "Select at least one employee role"),
      officeTypes: yup.array().min(1, "Select at least one office type"),
      officeLocations: yup
        .array()
        .min(1, "Select at least one office location"),
      forUserIds: yup.array(),
      notForUserIds: yup.array(),
      entity: yup.string().required("Entity is required"),
    }),
    text_default: yup.object({
      name: yup.string().required("Name is required").max(40),
      description: yup.string().required("Description is required").max(100),
      about: yup.string().required("About is required"),
      highlighted: yup.string(),
      howToApply: yup.string(),
      howToRedeem: yup.string(),
      documents: yup
        .array(
          yup
            .object({
              name: yup.string().nullable(),
              url: yup.string().nullable(),
            })
            .nullable()
        )
        .nullable(),
      video: yup.string(),
      support: yup.string(),
    }),
    text_en: yup.object({
      name: yup.string().max(40),
      description: yup.string().max(100),
      about: yup.string(),
      highlighted: yup.string(),
      howToApply: yup.string(),
      howToRedeem: yup.string(),
      documents: yup
        .array(
          yup
            .object({
              name: yup.string().nullable(),
              url: yup.string().nullable(),
            })
            .nullable()
        )
        .nullable(),
      video: yup.string(),
      support: yup.string(),
    }),
    benefitquestions: yup.array().of(
      yup.object().shape({
        idx: yup.number().integer(),
        text_default: yup.object().shape({
          question: yup.string(),
          //.required("Default language question is required"),
          answer: yup.string(),
          //.required("Default language answer is required"),
        }),
        text_en: yup.object().shape({
          question: yup.string(),
          //.required("English language question is required"),
          answer: yup.string(),
          //.required("English language answer is required"),
        }),
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isNew
      ? {
          isNew,
          cmp: "9aa46b2c-5fa6-4f0c-839d-35527dd19386",
          pillars: [],
          category_id: undefined,
          subcategory_id: undefined,
          image: undefined,
          previewImage: undefined,
          analytics_name: undefined,
          isPublished: false,
          userCanGiveFeedback: true,
          viaBookingSystem: false,
          booking_id: null,
          freeComment: true,
          questionAboutUsed: true,
          openInWecare: false,
          userCanSubscribe: false,
          keyInfo: {
            contacts: [""],
            manager: undefined,
            supportContacts: [""],
            sites: [""],
            usefullLinks: [],
            expirationDate: null,
          },
          text_default: {
            name: undefined,
            description: undefined,
            about: undefined,
            highlighted: undefined,
            howToApply: undefined,
            howToRedeem: undefined,
            documents: [],
            video: undefined,
            support: undefined,
          },
          benefitquestions: [],
          text_en: {
            name: undefined,
            description: undefined,
            about: undefined,
            highlighted: undefined,
            howToApply: undefined,
            howToRedeem: undefined,
            documents: [],
            video: undefined,
            support: undefined,
          },
          conditions: {
            entity: undefined,
            contractTypes: [],
            employeeTypes: [],
            employeeRoles: [],
            officeTypes: [],
            officeLocations: [],
            forUserIds: [],
            notForUserIds: [],
          },
        }
      : benefit
      ? {
          id: benefit.id,
          cmp: "9aa46b2c-5fa6-4f0c-839d-35527dd19386",
          pillars: benefit.pillars,
          userCanGiveFeedback: benefit.usercangivefeedback,
          booking_id: benefit.booking_id,
          viaBookingSystem: benefit.booking_id ? true : false,
          freeComment: benefit.freecomment,
          questionAboutUsed: benefit.questionaboutused,
          userCanSubscribe: benefit.usercansubscribe || false,
          category_id: benefit.category_id,
          subcategory_id: benefit.subcategory_id,
          image: benefit.image,
          previewImage: benefit.previewimage,
          analytics_name: benefit.analytics_name,
          text_default: benefit.text_default,
          text_en: benefit.text_en,
          isPublished: benefit.ispublished || false,
          openInWecare: benefit.openinwecare || false,
          keyInfo: {
            contacts:
              benefit.keyinfo?.contacts && benefit.keyinfo.contacts.length > 0
                ? benefit.keyinfo.contacts
                : [""],
            manager: benefit.keyinfo?.manager || undefined,
            supportContacts:
              benefit.keyinfo?.supportContacts &&
              benefit.keyinfo.supportContacts.length > 0
                ? benefit.keyinfo.supportContacts
                : [""],
            sites:
              benefit.keyinfo?.sites && benefit.keyinfo.sites.length > 0
                ? benefit.keyinfo.sites
                : [""],
            usefullLinks:
              benefit.keyinfo?.usefullLinks &&
              benefit.keyinfo.usefullLinks.length > 0
                ? benefit.keyinfo.usefullLinks
                : [""],
            expirationDate: benefit.keyinfo?.expirationDate || null,
          },
          benefitquestions: benefit.benefitquestions || [],
          conditions: {
            id: benefit.condition_id,
            entity: benefit.legalentity_id,
            contractTypes: benefit.contracttypenames,
            employeeTypes: benefit.employeetypenames,
            employeeRoles: benefit.employeerolenames,
            officeTypes: benefit.officetypenames,
            officeLocations: benefit.officelocationnames,
            forUserIds: benefit.foruser_ids || [],
            notForUserIds: benefit.notforuser_ids || [],
          },
        }
      : null,
    onSubmit: async (values) => {
      const updatedBenefit = await updateWelfareBenefit(values);

      if (!values.isNew || !updatedBenefit?.id) {
        return;
      }

      history.replace({
        ...location,
        pathname: location.pathname.replace("new", updatedBenefit.id),
        state: null,
      });
    },
    validationSchema: editSchema,
    validator: () => ({}),
  });

  if (
    isLoading ||
    isBenefitLoading ||
    isWelfareSelectorsLoading ||
    isWelfareCategoriesLoading ||
    isWelfareSubcategoriesLoading
  ) {
    return <Loader />;
  }

  if (!formik.values) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="subtitle1">Benefit is not found 😢</Typography>
      </Box>
    );
  }

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormikProvider value={formik}>
            <Welfare
              //formik={formik}
              isNew={isNew}
              tab={tab}
              welfareCategories={welfareCategories}
              welfareSelectors={welfareSelectors}
              welfareSubcategories={welfareSubcategories}
            />
          </FormikProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WelfareContainer;
