import React, { useCallback } from "react";
import {
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import { getFieldProps, inputProps } from "../../utils";
import InfoTooltip from "../../InfoTooltip";
import ImageUpload from "../../ImageUpload";

const InitialInformation = () => {
  const formik = useFormikContext();

  const buildTextField = (
    formik,
    placeholder,
    name,
    label,
    required = false
  ) => {
    return (
      <>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={inputProps}
          label={label}
          margin="none"
          placeholder={placeholder}
          required={required}
          variant="outlined"
          {...getFieldProps(formik, {
            name,
          })}
        />
      </>
    );
  };

  const handleImageChange = useCallback(
    ({ url }) => {
      formik.setFieldValue(`image`, url);
      formik.setFieldTouched(`image`, true);
    },
    [formik]
  );

  const handleImageReset = useCallback(() => {
    formik.setFieldValue("image", null);
    formik.setFieldTouched("image", true);
  }, [formik]);

  return (
    <Grid item xs={12}>
      <Grid item>
        <Typography variant="h6">
          In this section you will need to upload the appearance of your news!
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.isSubscribed}
              color="primary"
              name="isSubscribed"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          }
          label="Let the users subscribe to the news"
        />
        {formik.values.isSubscribed && (
          <Grid item md={6} xs={12}>
            {buildTextField(
              formik,
              "Fields assigned",
              "subscription",
              "Fields assigned"
            )}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Grid item style={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <Typography variant="h6">
              The headline of the news ! Be sure to make it engaging and
              intuitive !
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              {buildTextField(
                formik,
                "Write news headline...",
                `text_default.title`,
                "Default language",
                true
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {buildTextField(
                formik,
                "Write news headline...",
                `text_en.title`,
                "English"
              )}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{ marginBottom: "1rem", marginTop: "1rem" }}
          >
            <Grid item md={6} xs={12}>
              {buildTextField(
                formik,
                "Write a subheading...",
                `text_default.subtitle`,
                "Default language",

                true
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {buildTextField(
                formik,
                "Write a subheading...",
                `text_en.subtitle`,
                "English"
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h6">
                  {" "}
                  Finally, upload the cover image!
                </Typography>
              </Grid>
              <Grid item>
                <InfoTooltip
                  text="Upload .jpg or .png files. The uploaded picture will be displayed with 1200px width and 250px height.
                  In case of size mismatch, the picture will be scaled horizontally to 1200px and cropped vertically (a central stripe of 250px height will be taken)"
                  title="Image Dimensions"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6} xs={12}>
            <ImageUpload
              buttonProps={{
                children: "Upload",
              }}
              caption={
                <Typography
                  color="textSecondary"
                  style={{ color: "#BFBFBF" }}
                  variant="body2"
                >
                  Upload news cover image (.png, .jpg, .jpeg)
                </Typography>
              }
              containerStyles={{ height: 250 }}
              customPathInfo={{ cmp: formik.values?.cmp }}
              errorMessage={getFieldProps(formik, { name: "image" }).helperText}
              maxSize={1.5 * 1024 * 1024} //1.5MB
              styles={{ maxHeight: 250, width: "unset" }}
              value={formik.values.image}
              onChange={handleImageChange}
              onReset={handleImageReset}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InitialInformation;
