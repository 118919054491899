/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import * as yup from "yup";
import invert from "lodash/invert";
import Stepper from "@material-ui/core/Stepper";
import StepButton from "@material-ui/core/StepButton";
import Step from "@material-ui/core/Step";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import SaveButtons from "../SaveButtons";

import Loader from "../shared/Loader";
import { useAppContext } from "../../api/AppContext";
import { isEmpty, pickBy } from "lodash";
import { getFieldProps } from "../utils";

import InitialInformation from "./steps/NewsInitialInformation";
import NewsText from "./steps/NewsText";
import KeyPoints from "./steps/NewsKeyPoints";
import Publish from "./steps/Publish";
import Start from "./steps/Start";
import Requirements from "./steps/Requirements";

import { DatePicker } from "@material-ui/pickers";

const steps = [
  "Let's get started",
  "Access criteria to the news",
  "Initial information about the news",
  "News text",
  "Key points",
  "Confirm and publish",
];

const tabs = {
  "first-information": 2,
  "key-points": 4,
  "news-text": 3,
  publish: 5,
  requirement: 1,
  start: 0,
};

const textPrimary =
  "Hello, In this section you will be able to post a news about the WECARE portal!";

const textInfo =
  "To create a news story you must have the following information:";

const textConditions = [
  "Who can see it?",
  "The text of the news, with title and subtitle",
  "The summarized key points",
  "Image and optional Video",
];

const CustomStartStep = ({ textPrimary, textInfo, textConditions }) => {
  const formik = useFormikContext();

  const handleDateChange = useCallback(
    (field, value) => {
      formik.setFieldValue(field, value);
      formik.setFieldTouched(field);
    },
    [formik]
  );

  return (
    <>
      <Start
        textConditions={textConditions}
        textInfo={textInfo}
        textPrimary={textPrimary}
      />
      <Grid container spacing={2}>
        <Grid item lg={3} md={3} xs={12}>
          <DatePicker
            autoOk
            disablePast
            fullWidth
            ampm={false}
            format="dd MMMM yyyy"
            inputVariant="outlined"
            label={"Start date"}
            {...getFieldProps(formik, { name: "startDate" })}
            InputLabelProps={{
              shrink: true,
              style: { pointerEvents: "all" },
            }}
            maxDate={formik.values.expirationDate}
            maxDateMessage="Start date should not be after expiry date"
            variant="inline"
            onChange={(val) => handleDateChange("startDate", val)}
          />
        </Grid>
        <Grid item lg={3} md={3} xs={12}>
          <DatePicker
            autoOk
            disablePast
            fullWidth
            ampm={false}
            format="dd MMMM yyyy"
            inputVariant="outlined"
            label={"Expiry date"}
            {...getFieldProps(formik, { name: "expirationDate" })}
            InputLabelProps={{
              shrink: true,
              style: { pointerEvents: "all" },
            }}
            minDate={formik.values.startDate}
            minDateMessage="Date should not be before start date"
            variant="inline"
            onChange={(val) => handleDateChange("expirationDate", val)}
          />
        </Grid>
      </Grid>
    </>
  );
};
const News = ({ tab, isNew, selectors }) => {
  const formik = useFormikContext();
  const [activeStep, setActiveStep] = useState(tabs[tab] ?? 0);
  const history = useHistory();
  const location = useLocation();

  const handleStep = useCallback(
    (step) => () => {
      history.replace({
        ...location,
        pathname: location.pathname.replace(
          new RegExp(
            `/news/${formik.values.isNew ? "new" : formik.values.id}(/.+)?`,
            "g"
          ),
          `/news/${formik.values.isNew ? "new" : formik.values.id}/${
            invert(tabs)[step]
          }`
        ),
      });
      setActiveStep(step);
    },
    [formik.values.id, formik.values.isNew, history, location]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid item xs={12}>
        <>
          <div
            style={{
              display: activeStep === 0 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <CustomStartStep
                textConditions={textConditions}
                textInfo={textInfo}
                textPrimary={textPrimary}
              />
            </Grid>
          </div>
          <div
            style={{
              display: activeStep === 1 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <Requirements formik={formik} welfareSelectors={selectors} />
            </Grid>
          </div>
          <div
            style={{
              display: activeStep === 2 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <InitialInformation />
            </Grid>
          </div>
          <div
            style={{
              display: activeStep === 3 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <NewsText />
            </Grid>
          </div>
          <div
            style={{
              display: activeStep === 4 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <KeyPoints />
            </Grid>
          </div>
          <div
            style={{
              display: activeStep === 5 ? "block" : "none",
              padding: "16px",
            }}
          >
            <Grid container spacing={4}>
              <Publish
                buttonText={"Click to see the News Preview"}
                previewLink={"https://angelini.habitinspiring.com/news"}
                title={
                  "Congratulations!!!. Before previewing the news, make sure to save it."
                }
              />
            </Grid>
          </div>
          <Grid item xs={12}>
            {activeStep === steps.length - 1 ? (
              <Grid item xs={12}>
                <SaveButtons
                  formik={formik}
                  isNew={isNew}
                  isShowSaveErrors={true}
                />
              </Grid>
            ) : (
              <Button
                color="primary"
                style={{ marginTop: "1rem" }}
                variant="outlined"
                onClick={handleStep(activeStep + 1)}
              >
                Next
              </Button>
            )}
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

const preprocessPercentages = (values, fieldNames) => {
  fieldNames.forEach((field) => {
    if (values[field]) {
      values[field] = values[field].trim();
      if (!values[field].includes("%")) values[field] = `${values[field]}%`;
    }
  });
};

const NewsContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true);
  const [news, setNews] = useState(null);
  const { id, tab } = match.params;
  const { isNew } = location.state || {};
  const defaultStartDate = new Date();
  defaultStartDate.setHours(24, 0, 0, 0); // next midnight, in current timezone
  const defaultExpirationDate = new Date(defaultStartDate.getTime()); // copy defaultStartDate
  defaultExpirationDate.setHours(defaultExpirationDate.getHours() + 23, 59); // add 23h and 59 minutes

  const {
    fetchWelfareSelector,
    welfareSelectors: selectors,
    isWelfareSelectorsLoading: isSelectorsLoading,
    fetchNews,
    isNewsLoading,
    updateWelfareNews,
  } = useAppContext();

  useEffect(() => {
    if (!isSelectorsLoading) {
      const emptyArrays = pickBy(selectors, (value) => isEmpty(value));
      Promise.all(
        Object.keys(emptyArrays)?.map((elem) => fetchWelfareSelector(elem))
      );
    }
  }, [fetchWelfareSelector, isSelectorsLoading, selectors]);

  useEffect(() => {
    if (!id || isNew || id === "new") {
      setIsLoading(false);
      return;
    }

    fetchNews(id).then((resp) => {
      setNews(resp);
    });
  }, [id, isNew, fetchNews]);

  useEffect(() => {
    setIsLoading(false);
  }, [news]);

  const editSchema = yup.object({
    conditions: yup.object({
      contractTypes: yup.array().min(1, "Select at least one contract type"),
      employeeRoles: yup.array().min(1, "Select at least one employee role"),
      employeeTypes: yup.array().min(1, "Select at least one employee type"),
      entity: yup.string().required("Entity is required"),
      forUserIds: yup.array(),
      notForUserIds: yup.array(),
      officeLocations: yup
        .array()
        .min(1, "Select at least one office location"),
      officeTypes: yup.array().min(1, "Select at least one office type"),
    }),
    expirationDate: yup
      .date()
      .when(
        "startDate",
        (startDate, yup) =>
          startDate && yup.min(startDate, "Expiry should be after start")
      )
      .required("Expiry date is required"),
    image: yup.string().required("Cover image is required"),
    imgbeforeintro: yup.string().nullable(),
    imgbeforeintropercent: yup.string().when("imgbeforeintro", {
      is: (imgbeforeintroValue) => imgbeforeintroValue != null,
      otherwise: yup.string().nullable(),
      then: yup
        .string()
        .max(4, "img-before-intro Width cannot exceed 4 characters")
        .required("img-before-intro Width is required"),
    }),
    imgbeforemain: yup.string().nullable(),
    imgbeforemainpercent: yup.string().when("imgbeforemain", {
      is: (imgbeforemainValue) => imgbeforemainValue != null,
      otherwise: yup.string().nullable(),
      then: yup
        .string()
        .max(4, "img-before-main Width cannot exceed 4 characters")
        .required("img-before-main Width is required"),
    }),
    imgendofnews: yup.string().nullable(),
    imgendofnewspercent: yup.string().when("imgendofnews", {
      is: (imgendofnewsValue) => imgendofnewsValue != null,
      otherwise: yup.string().nullable(),
      then: yup
        .string()
        .max(4, "img-end-of-news Width cannot exceed 4 characters")
        .required("img-end-of-news Width is required"),
    }),
    isPublished: yup.boolean().nullable(),
    startDate: yup.date().required("Start date is required"),

    subtitle: yup.string(),
    summary: yup.string(),
    text: yup.string(),
    text_default: yup.object({
      keyPoints: yup
        .array(yup.string().required("Key point can't be empty"))
        .min(1, "Insert at least 1 key point"),
      subtitle: yup.string().required("Subheading is required"),
      summary: yup.string().required("Introduction is required"),
      text: yup.string().required("Text for the news is required"),
      title: yup.string().required("Headline is required"),

      video: yup.string(),
    }),
    text_en: yup.object({
      keyPoints: yup.array(yup.string()).nullable(),
    }),
    title: yup.string(),

    video: yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isNew
      ? {
          isNew,
          cmp: "9aa46b2c-5fa6-4f0c-839d-35527dd19386",
          image: undefined,
          imgbeforeintro: undefined,
          imgbeforemain: undefined,
          imgendofnews: undefined,
          imgbeforeintropercent: "50%",
          imgbeforemainpercent: "50%",
          imgendofnewspercent: "50%",
          isPublished: false,
          expirationDate: defaultExpirationDate,
          startDate: defaultStartDate,
          text_default: {
            title: undefined,
            subtitle: undefined,
            keyPoints: [""],
            summary: undefined,
            text: undefined,
            video: undefined,
          },
          text_en: {
            title: undefined,
            subtitle: undefined,
            keyPoints: [""],
            summary: undefined,
            text: undefined,
            video: undefined,
          },
          conditions: {
            entity: undefined,
            contractTypes: [],
            employeeTypes: [],
            employeeRoles: [],
            officeTypes: [],
            officeLocations: [],
            forUserIds: [],
            notForUserIds: [],
          },
        }
      : news
      ? {
          id: news.id,
          cmp: "9aa46b2c-5fa6-4f0c-839d-35527dd19386",
          image: news.image,
          imgbeforeintro: news.imgbeforeintro,
          imgbeforemain: news.imgbeforemain,
          imgendofnews: news.imgendofnews,
          imgbeforeintropercent: news.imgbeforeintropercent,
          imgbeforemainpercent: news.imgbeforemainpercent,
          imgendofnewspercent: news.imgendofnewspercent,
          text_default: news.text_default,
          text_en: news.text_en,
          expirationDate: new Date(news.expirationdate),
          startDate: new Date(news.startdate),
          isPublished: news.ispublished || false,
          conditions: {
            id: news.condition_id,
            entity: news.legalentity_id,
            contractTypes: news.contracttypenames,
            employeeTypes: news.employeetypenames,
            employeeRoles: news.employeerolenames,
            officeTypes: news.officetypenames,
            officeLocations: news.officelocationnames,
            forUserIds: news.foruser_ids || [],
            notForUserIds: news.notforuser_ids || [],
          },
        }
      : null,
    onSubmit: async (values) => {
      // preprocess percentages; be sure % is set
      preprocessPercentages(values, [
        "imgbeforeintropercent",
        "imgbeforemainpercent",
        "imgendofnewspercent",
      ]);

      const updatedNews = await updateWelfareNews(values);

      if (!values.isNew || !updatedNews?.id) {
        return;
      }

      history.replace({
        ...location,
        pathname: location.pathname.replace(
          "news/new",
          `news/${updatedNews.id}`
        ),
        state: null,
      });
    },
    validationSchema: editSchema,
    validator: () => ({}),
  });

  if (isLoading || isNewsLoading || isSelectorsLoading) {
    return <Loader />;
  }

  if (!formik.values) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="subtitle1">News is not found 😢</Typography>
      </Box>
    );
  }

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormikProvider value={formik}>
            <News isNew={isNew} selectors={selectors} tab={tab} />
          </FormikProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsContainer;
