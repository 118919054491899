/* eslint-disable prettier/prettier */
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Timestamps from "../Timestamps";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect } from "react";
import Loader from "../shared/Loader";
import { useAppContext } from "../../api/AppContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useModals } from "../../hooks/useModals";

const Accordion = withStyles({
  root: {
    "&:before": {
      height: 0,
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    "&.Mui-expanded": {
      margin: 0,
    },
    margin: 0,
  },
})(MuiAccordionSummary);


const WelfareBooking = () => {
  const modals = useModals();
  const {
    fetchWelfareBookings,
    welfareBookings,
    updateWelfareBooking,
    deleteWelfareBooking,
    isWelfareBookingsLoading,
    fetchAllBenefits,
    benefits,
    isAllBenefitsLoading,
    fetchWelfareSelector,
    welfareSelectors,
    isWelfareSelectorsLoading,
  } = useAppContext();

  const [expanded, setExpanded] = useLocalStorage(
    `WelfareBookingList.Expanded`,
    {},
  );

  const handleExpandClick = useCallback(
    (step) => () => {
      setExpanded((expanded) => ({
        ...expanded,
        [step]: !expanded[step],
      }));
    },
    [setExpanded],
  );

  useEffect(() => {
    if (!welfareSelectors.entities.length) {
      fetchWelfareSelector("entities");
    }
    fetchAllBenefits();
    fetchWelfareBookings();
  }, [fetchAllBenefits, fetchWelfareBookings, fetchWelfareSelector]);
  const handleCreate = useCallback(() => {
    modals.openModal(modals.modalTypes.EditWelfareBooking, {
      isNew: true,
      onConfirm: (booking) =>
        updateWelfareBooking({
          isNew: true,
          ...booking,
        }).then(() => {
          fetchWelfareBookings();
        }),
    });
  }, [modals, updateWelfareBooking, fetchWelfareBookings]);

  const handleEdit = useCallback(
    (booking) => (e) => {
      e.stopPropagation();
      modals.openModal(modals.modalTypes.EditWelfareBooking, {
        bookingId: booking.id,
        bookingRecord: booking,
        onConfirm: (booking) => updateWelfareBooking(booking),
      });
    },
    [modals, updateWelfareBooking],
  );
  const handleDelete = useCallback(
    (booking) => (e) => {
      e.stopPropagation();

      modals.openConfirmation({
        onConfirm: async () => {
          await deleteWelfareBooking({
            id: booking.id,
            isdeleted: true,
            maxperuserperyear: booking.maxperuserperyear,
            name: booking.name,
            url: booking.url,
          });
        },
        text: `Do you want to delete booking "${booking.name}"?`,
      });
    },
    [modals, deleteWelfareBooking],
  );

  const getWelfareSelectorDisplayName = (id) => {
    const selector = welfareSelectors.entities.find(
      (entity) => entity.id === id,
    );
    return selector ? selector.displayname : "";
  };
  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      {isWelfareBookingsLoading ||
      isAllBenefitsLoading ||
      isWelfareSelectorsLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="h6">WECARE{"'"}S Bookings</Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="body2">
                  Use this section to configure the Booking systems within
                  WeCare
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              endIcon={<AddIcon />}
              variant="contained"
              onClick={handleCreate}
            >
              Add new Booking
            </Button>
          </Grid>
          <Grid item>
            {welfareBookings
              .filter((booking) => !booking.isdeleted)
              .map((booking, i) => (
                <Accordion
                  key={i}
                  expanded={Boolean(expanded[i])}
                  onChange={handleExpandClick(i)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid
                      container
                      justifyContent="space-between"
                      spacing={1}
                      style={{ width: "100%" }}
                    >
                      <Grid item style={{ flexGrow: 1 }}>
                        <Grid item>
                          <Typography variant="h6">{booking.name}</Typography>
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <Tooltip title="Edit">
                              <IconButton onClick={handleEdit(booking)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Delete">
                              <IconButton onClick={handleDelete(booking)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper style={{ width: "100%" }} variant="outlined">
                      <Box p={2}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Grid item>
                              <Timestamps
                                createdAt={booking.createdAt}
                                createdBy={booking.createdBy}
                                updatedAt={booking.updatedAt}
                                updatedBy={booking.updatedBy}
                              />
                            </Grid>
                          </Grid>

                          {benefits
                            .filter(
                              (benefit) => benefit.booking_id === booking.id,
                            )
                            .map((benefit) => (
                              <Grid key={benefit.id} item>
                                <List>
                                  <ListItem>
                                    <ListItemText
                                      primary={benefit.text_default.name}
                                      secondary={getWelfareSelectorDisplayName(
                                        benefit.legalentity_id,
                                      )}
                                    />
                                    <ListItemSecondaryAction>
                                      <a
                                        href={`/welfares/${benefit.id}`}
                                        rel="noreferrer noopener"
                                        target="_blank"
                                      >
                                        <Tooltip title="Edit">
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </a>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                </List>
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default WelfareBooking;
