import React, { memo } from "react";
import { Route, Switch } from "react-router-dom";

import Box from "@material-ui/core/Box";

import Interaction from "./Interaction";
import Journeys from "./Journeys";
import Version from "./shared/Version";
import { CreateJourney, EditJourney } from "./Journey/index";
import Settings from "./Settings/index";
import ScrollToTop from "./shared/ScrollToTop";
import Material from "./Material";
import Survey from "./Journey/Surveys/Survey";
import AdminRoute from "./shared/AdminRoute";
import ConfigurationPage from "./Settings/ConfigurationPage";
import UserPage from "./Settings/UserPage";
import CompanyPage from "./Settings/CompanyPage";
import ImageExport from "./ImageExport";
import Welfares from "./Welfares";
import Copilot from "./Copilot/index";
import Prompt from "./Copilot/Prompt";
import Tags from "./Copilot/Tags";
import Tools from "./Copilot/Tools";
import NewsList from "./News/NewsList";
import NewsContainer from "./News/NewsContainer";
import AdaptiveCardPreview from "./AdaptiveCardPreview";
// import WelfareContainer from "./Welfares/Welfare";

const MainApp = () => {
  return (
    <>
      <Box
        className="scrollarea"
        display="flex"
        height="calc(100% - 37px)"
        mt={
          window.location.pathname.includes("journeys") &&
          !window.location.pathname.includes("primes") &&
          !window.location.pathname.includes("nudges") &&
          !window.location.pathname.match(/surveys\/.+/) &&
          !window.location.pathname.match(/learning-materials\/.+/)
            ? 11.5
            : 6
        }
        overflow="auto"
      >
        <Switch>
          <Route
            exact
            component={Interaction}
            path="/journeys/:journeyId/:locale/:interactionType(primes|nudges)/:semanticId"
          />
          <Route
            exact
            component={Material}
            path="/journeys/:journeyId/:locale/learning-materials/:id"
          />
          <Route
            exact
            component={Survey}
            path="/journeys/:journeyId/:locale/surveys/:id/:tab?"
          />
          <Route
            exact
            component={AdaptiveCardPreview}
            path="/adaptivecardpreview/:semanticId/:deviceId"
          />
          <Route
            exact
            component={(props) => {
              return props.location.state?.isNew ? (
                <CreateJourney {...props} />
              ) : (
                <EditJourney {...props} />
              );
            }}
            path="/journeys/:journeyId/:locale/:tab?"
          />
          <Route
            exact
            component={Settings}
            path="/settings/:tab(integrations)/:type?"
          />
          <AdminRoute exact component={Settings} path="/settings/:tab?" />
          <AdminRoute
            exact
            component={ConfigurationPage}
            path="/settings/cid-configurations/:cid"
          />
          <AdminRoute
            exact
            component={CompanyPage}
            path="/settings/companyuser-configuration/company/:id"
          />
          <AdminRoute
            exact
            component={UserPage}
            path="/settings/companyuser-configuration/user/:id"
          />
          <Route component={Welfares} path="/welfares/:tab?" />
          <Route exact component={NewsList} path="/news" />
          <Route exact component={NewsContainer} path="/news/new/:tab?" />
          <Route exact component={NewsContainer} path="/news/:id/:tab?" />
          <AdminRoute exact component={Copilot} path="/copilot/:tab?" />
          <AdminRoute exact component={Prompt} path="/copilot/prompt/:id" />
          <AdminRoute exact component={Tags} path="/copilot/tags/:id" />
          <AdminRoute exact component={Tools} path="/copilot/tools/:id" />

          <AdminRoute exact component={ImageExport} path="/export" />
          <Route component={Journeys} path="/" />
        </Switch>
      </Box>
      <Version />
      <ScrollToTop />
    </>
  );
};

export default memo(MainApp);
